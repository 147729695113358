import React, { useEffect, useState } from "react";
import "./Register.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import logo from "../../Assets/logo.svg";

const Register = () => {
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const [userType, setUserType] = useState("");
  const [userTypeErr, setUserTypeErr] = useState("");

  const [userOptions, setUserOptions] = useState([]);

  const [loading, setLoading] = useState(false);

  // Check Valid Email
  const isValidEmail = (email) => {
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    return regex.test(email);
  };

  // Validation
  const isValidForm = () => {
    let isValidData = true;

    if (name === "") {
      setNameErr("Required");
      isValidData = false;
    }

    if (email === "") {
      setEmailErr("Required");
      isValidData = false;
    } else if (!isValidEmail(email)) {
      setEmailErr("Please enter a valid email");
      isValidData = false;
    }

    if (userType === "") {
      setUserTypeErr("Required");
      isValidData = false;
    }

    return isValidData;
  };

  //   Handle Register
  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = {
      app_id: 2,
      username: name,
      email,
      accountType: userType?.label,
    };

    if (isValidForm()) {
      setLoading(true);

      axios
        .post(
          "https://api.ship2mates.com/v1/user/registerPortalUser",
          payload
        )
        // .post(
        //   "http://192.168.3.13:3000/api/v1/user/registerPortalUser",
        //   payload
        // )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setName("");
            setEmail("");
            setUserType("");
            toast.success("User Registered Successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setLoading(false);
          }
        })
        .catch(({ response }) => {
          setLoading(false);
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  // Dropdown Design
  const customStyles = {
    control: (base, state) => ({
      ...base,

      border: state.isFocused ? "1px solid #183541" : base.border,
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        border: state.isFocused ? "1px solid #183541" : base.border,
      },
      width: "100%",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#183541" : "white",
      ":hover": {
        backgroundColor: "#183541",
        color: "white",
      },
    }),
  };

  // Use Effects
  useEffect(() => {
    axios
      .get(
        "https://api.ship2mates.com/1/admin/get-subcategory-list?app_id=2&id=9"
      )
      .then((res) => {
        if (res.status === 200 && res.data) {
          const arr = res?.data?.data?.map((subcategory) => ({
            value: subcategory.label,
            label: subcategory.label,
            image: subcategory.icon,
          }));
          setUserOptions(arr);
        } else {
          console.log("Response status is not 200 or no data found");
        }
      })
      .catch((err) => {
        console.log("🚀 ~ file: Register.js:153 ~ useEffect ~ err:", err);
      });
  }, []);

  return (
    <>
      <div className="parent_register">
        <div className="text-center">
          <img src={logo} alt="logo" className="logo_width mb-4" />
          <h4>Register Now</h4>
          <p className="">Start Your Journey with Us!</p>
        </div>
        <form onSubmit={handleSubmit} autoComplete="off">
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Name<span className="staric">*</span>
              {nameErr && (
                <span className="text-danger error-text ps-2">{nameErr}</span>
              )}
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputPassword1"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameErr("");
              }}
            />
          </div>

          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Email Address<span className="staric">*</span>
              {emailErr && (
                <span className="text-danger error-text ps-2">{emailErr}</span>
              )}
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailErr("");
              }}
            />
          </div>

          <div className="mb-4">
            <label class="form-label">
              User Type<span className="staric">*</span>
              {userTypeErr && (
                <span className="text-danger error-text ps-2">
                  {userTypeErr}
                </span>
              )}
            </label>
            <Select
              styles={customStyles}
              options={userOptions}
              value={userType}
              onChange={(e) => {
                setUserType(e);
                setUserTypeErr("");
              }}
              placeholder="Select user type"
              formatOptionLabel={(item) => (
                <div className="item-option">
                  <img src={item?.image} alt="type" width="25px" />
                  <span className="ms-1">{item?.label}</span>
                </div>
              )}
              menuPlacement="auto"
            />
          </div>

          <button
            type="submit"
            class="btn btn-primary bg-btn w-100"
            disabled={loading}
          >
            {loading ? (
              <div class="spinner-border spinner-border-sm" role="status"></div>
            ) : (
              "Register"
            )}
          </button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default Register;
